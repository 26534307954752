import { getNft } from 'config/constants/getNftHng';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, AppState } from '../index';
import { fetchDataByApi, fetchDataNft, fetchDataNftById, fetchSeri } from './fetchData';
import { getDataById, getDataNft, getLoading, getMetaData } from './actions';

export const UseHome = (account:string, chainId:number, nftId: string) => {
    const dispatch = useDispatch<AppDispatch>()
    
    useEffect(() => {
        const getData = async() => {
            dispatch(getLoading({isLoading: !false}))
            const tokenHng = getNft(chainId)
            const { metaData: dataByID } = await fetchDataByApi(nftId)
            
            const publishDataNft = await fetchDataNft(tokenHng, chainId)
            const dataById = await fetchDataNftById(tokenHng, dataByID?.STT, chainId)
            dispatch(getDataNft(publishDataNft))
            dispatch(getDataById(dataById))
            dispatch(getMetaData({metaData: dataByID}))
            dispatch(getLoading({isLoading: false}))
        }
        if(nftId !== "") {
            getData()
        } 
        if(nftId === "") {
            dispatch(getDataNft({dataNft: null}))
            dispatch(getDataById({dataById: null}))
            dispatch(getMetaData({metaData : null}))
        }
    }, [account, chainId, dispatch, nftId])
}

export const UseSerinumber = (seri, setSearchId) => {
    
    useEffect(() => {
        const getData = async() => {
            const seriData = await fetchSeri(seri)
            if(seriData ) {
                setSearchId(seriData?.dataSeri?.ID)
            } else {
                setSearchId("")
            }
        }
        if(seri !== "") {
            getData()
        } 
    }, [seri, setSearchId])
}

export const GetDataHome = () => {
    const dataHome = useSelector<AppState, AppState['home']>((state) => state?.home)
    return dataHome
}
