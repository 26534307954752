import { Flex, Heading, Text } from "@pancakeswap/uikit";
import React from "react";
import styled from "styled-components";

interface Props {
    pageTitle: string,
    pageDescription?:string,
}
const Header: React.FC<Props> = ({pageTitle, pageDescription}) => {
    return (
        <ContainerBg>
            <CsHeading scale="xxl">{pageTitle}</CsHeading>
            {pageDescription?.length > 0 &&
                <CsSubtle>{pageDescription}</CsSubtle>
            }
        </ContainerBg>
    )
}

export default Header

const ContainerBg = styled(Flex)`
    width: 100%;
    max-width: 1280px;
    min-height: 60vh;
    @media screen and (max-width: 1280px) and (min-width: 1000px){
        min-height: 50vh;
        font-size: 36px;
    };
    @media screen and (max-width: 1000px) and (min-width: 601px){
        min-height: 45vh;
        font-size: 36px;
    };
    @media screen and (max-width: 600px){
        min-height: 30vh;
        padding-top:2rem;
    };
    @media screen and (max-width: 500px){
        min-height: 35vh;
        padding-top:2rem;
    };
    padding-top:3rem;
    justify-content: flex-start;
    align-items: center;
    gap:1.5rem;
    flex-direction:column;
`
const CsSubtle = styled(Text)`
    background: ${({ theme }) => theme.colors.colorgradientSubtle};
    font-weight: 600;
    font-size: 30px;
    letter-spacing: 0.02em;
    line-height: 44px;
    max-width: 1000px;
    width: 100%;
    font-family: 'Lexend', sans-serif;
    text-align: center;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    @media screen and (max-width: 1280px) and (min-width: 1001px){
        font-size: 36px;
    }
    @media screen and (max-width: 1000px) and (min-width: 601px){
        font-size: 28px;
    }
    @media screen and (max-width: 600px){
        font-size: 16px;
        line-height: 24px;
    }
`
const CsHeading = styled(Heading)`
    line-height: 1.4;
    @media screen and (max-width: 1280px) and (min-width: 1001px){
        font-size:70px;
    }
    @media screen and (max-width: 1000px) and (min-width: 601px){
        font-size:50px;
        line-height: 1.4;
    }
    @media screen and (max-width: 600px){
        font-size: 32px;
        line-height: 1.4;
    }
    @media screen and (max-width: 500px){
        font-size: 26px;
        text-align:center;
        line-height: 1.4;
    }
`