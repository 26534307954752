import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 28 28" fill="none" {...props}>
      <path fill="none" d="M12.7984 22.388C7.50171 22.388 3.20837 18.0947 3.20837 12.798C3.20837 7.50134 7.50171 3.20801 12.7984 3.20801C18.095 3.20801 22.3884 7.50134 22.3884 12.798C22.3884 18.0947 18.095 22.388 12.7984 22.388Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M24.7917 24.7911L19.9967 19.9961" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </Svg>
  );
};

export default Icon;
