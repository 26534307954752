import { useTranslation } from "@pancakeswap/localization";
import React from 'react';
import MetaTags from 'react-meta-tags';
import styled from 'styled-components';
import { Flex, Text, Link, useMatchBreakpoints } from "@pancakeswap/uikit";
// import { useLocation } from 'react-router'
import { DEFAULT_META, getCustomMeta } from 'config/constants/meta';
import { useRouter } from 'next/router';
import { MENU_HEIGHT } from "@pancakeswap/uikit/src/widgets/Menu/config";

const PageMeta = () => {                                                                                                                  
  const {
    t,
    currentLanguage: { locale },
  } = useTranslation()
  const { pathname } = useRouter()
  const pageMeta = getCustomMeta(pathname, t, locale) || {}
  const { title, description, image } = { ...DEFAULT_META, ...pageMeta }
  const pageTitle = title
  
  return (
    <MetaTags>
      <title>{pageTitle}</title>
      <meta name="description" content={description}/>
      <meta property="og:title" content={title} />
      <meta property="og:image" content={image} />
    </MetaTags>
  )
}

const PageFullWidth: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({ children, ...props }) => {
  const { isMobile } = useMatchBreakpoints()

  return (
        <Wrapper>
            <PageMeta />
            <ContainerBg/>
            <ContainerBlur/>
            <ContainerChild>
              {children}
              <Flex
                width="100%"
                height={["180px",,"120px"]}
                maxWidth="1320px"
                borderTop="1px solid rgba(255, 255, 255, 0.10)"
                alignItems="center"
              >
                <Flex
                  width="100%"
                  justifyContent="space-between"
                  alignItems="center"
                  flexDirection={["column-reverse",,"row"]}
                  style={{gap:"12px"}}
                >
                  <Text color="#BDBDBD">Copyright © HanaGold</Text>
                  <Flex
                    alignItems="center" 
                    style={{gap:"12px"}}
                  >
                      <Link
                        external
                        href='https://play.google.com/store/apps/details?id=com.hana.gold'
                      >
                          <img src="/images/hanagold/googleplay.png" alt="logo" style={{width: isMobile ? "130px" :"167px", height:"auto"}}/>
                      </Link>
                      <Link
                        external
                        href='https://apps.apple.com/vn/app/hanagoldvn/id1611748758'
                      >
                          <img src="/images/hanagold/appstore.png" alt="logo" style={{width: isMobile ? "130px" :"167px", height:"auto"}}/>
                      </Link>
                  </Flex>
                </Flex>
              </Flex>
            </ContainerChild>
        </Wrapper>
  )
}

export default PageFullWidth

const Wrapper = styled.div`
    width:100%;
    height: auto;
    min-height: calc(100vh - 90px);
    height: auto;
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction: column;
    background-color: ${({ theme }) => theme.colors.background};
    margin-top: -${MENU_HEIGHT}px;
    position: relative;
`
const ContainerBg = styled.div`
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 2;
    background:url('/images/hanagold/bg.png?version=1.1');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: bottom;
    @media screen and (max-width: 1024px) and (min-width: 601px){
      height: 90vh;
    }
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1;
`
const ContainerBlur = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1;
    background:url('/images/hanagold/bg_blur.svg?version=1.0');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
`

const ContainerChild = styled.div`
    width: 100%;
    height: auto;
    min-height: 80vh;
    margin-top: ${MENU_HEIGHT}px;
    z-index: 10;
    display: flex;
    flex-direction:column;
    justify-content: flex-start;
    align-items: center;
`