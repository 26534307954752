import { Flex } from "@pancakeswap/uikit";
import { Input } from "antd";
import styled from "styled-components";

export const ContainerImg = styled(Flex)`
    @media screen and (max-width:600px) {
        display: none;
    }
`

export const ContainerImgMobile = styled(Flex)`
    display: none;
    @media screen and (max-width:600px) {
        display: flex;
    }
`

export const CsInput = styled(Input)`
    width: 100%;
    height: 48px;
    padding-left: 16px;
    padding-right: 16px;
    border-radius: 40px;
    :focus-within {
        border-color: white;
    }
    :focus {
        border-color: 1px solid white;
    }
    :hover {
        border-color: 1px solid white;
    }
    .ant-input-clear-icon {
        color: white;
    }
`