import React from "react";
import styled from "styled-components";
import { PolymorphicComponent } from "../../util/polymorphic";
import Button from "../Button/Button";
import { BaseButtonProps, variants } from "../Button/types";
import { ButtonMenuItemProps } from "./types";

interface InactiveButtonProps extends BaseButtonProps {
  forwardedAs: BaseButtonProps["as"];
}

const InactiveButton: PolymorphicComponent<InactiveButtonProps, "button"> = styled(Button)<InactiveButtonProps>`
    background-color: transparent;
    color: ${({ theme, variant }) => (variant === variants.PRIMARY ? theme.colors.primary : theme.colors.primary)};
    &:hover:not(:disabled):not(:active) {
      background-color: transparent;
    }
    text-transform: uppercase;

`;

const ButtonMenuItem: PolymorphicComponent<ButtonMenuItemProps, "button"> = ({
  isActive = false,
  variant = variants.PRIMARY,
  as,
  ...props
}: ButtonMenuItemProps) => {
  if (!isActive) {
    return <InactiveButton forwardedAs={as} variant="text" {...props} />;
  }

  return <Button as={as} variant={variant} {...props} style={{textTransform: "uppercase"}}/>;
};

export default ButtonMenuItem;
